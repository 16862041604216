import DataPage from '../wrappers/DataPage'
import OrdersCards from '../tables/OrdersCards'

export default function Orderspage() {

    return (
        <DataPage
            Content={<OrdersCards />}
            title={"Orders"}
        />
    )
}