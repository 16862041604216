import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import DataField from "../DataField";

import format from "date-fns/format";

export default function ScheduleInfo(futureDate, schedule) {
  return (
    <div>
      <div
        style={{
          padding: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            // height: "2em"
          }}
        >
          {format(futureDate.date, "PPPP")}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            // height: "2em"
          }}
        >
          {format(futureDate.date, "'Begins at' hh:mmaaa")}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          //   border: "2px solid",
          flex: 1,
        }}
      >
        <div
          style={{
            flex: 3,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-around",
            // border: "2px solid",
            flex: 1,
            marginRight: "50px",
          }}
        >
          <Typography
            sx={{textAlign: "center"}}
          >Foil Schedule</Typography>
          {schedule.products.map((productEntry) => {
            return (
              <DataField
                key={productEntry.product._id}
                field={productEntry.product.name}
                data={productEntry.quantity}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function productList(productArray) {
  return (
    <div>
      <Typography>To Produce:</Typography>
      <List>
        {productArray.map((p) => {
          return (
            <ListItem
              disablePadding
              key={p.product._id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <ListItemText primary={`${p.product.name} ---- ${p.quantity}`} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
