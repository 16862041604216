import { useQueryClient, useMutation } from "react-query";
import compareAsc from "date-fns/compareAsc";
import isSameDay from 'date-fns/isSameDay'
import { cacheKeys } from "../../enums/cacheKeys";
import {cancelQueriesAndSaveData} from "./commonMutationFunctions"

export function useCancelOrder() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLORDERS;
  const cachesToInvalidate = [cacheKeys.ALLORDERS, cacheKeys.ALLCANCELLEDORDERS];

  return useMutation(
    async ({ user, orderToCancel }) => {
      console.log("starting cancel")
      if (user) {
        console.log("_id", orderToCancel._id);
        await fetch(
          "/api/orders/cancel/" + orderToCancel._id, 
          {
            method: "GET",
            headers: {
              authorization: "Bearer " + user?.jwt,
              "Content-Type": "application/json",
          },
        });
        return;
      }
    },
    {
      onMutate: async ({ orderToCancel }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        queryClient.setQueryData(cacheToMutate, (oldData) => {
          return oldData.filter((orderToRemove) => {
            return orderToRemove._id !== orderToCancel._id;
          });
        });
        return context;
      },
      onError: (err, input, context) => {
        console.log("error", err);
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
    }
  );
}