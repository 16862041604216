import {useContext} from 'react'
import { Navigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'

import { UserContext } from '../../contexts'
import { ReplaceContent } from "../Transitions";

function ProtectedRoute({
    Passthrough,
    redirect,
}) {

    const user = useContext(UserContext)

    //TODO: implement permitted roles/permissions here
    if (user === false) {
        return <Navigate to={redirect} replace />
    } else if (user === null) {
        return (
            <ReplaceContent content="Loading..." />
        )
    }
    return Passthrough
}

export default ProtectedRoute;

