import {createTheme} from '@mui/material'

const blueDarkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: '#1f1f1f'
        },
        //defaults: primary, secondary, error, warning, info, success
        primary: {
            main: '#2979ff'
        },
        secondary: {
            main: '#d32f2f'
        },
        activate: {
            main: '#1e88e5'
        },
        neutral: {
            main: "#757575"
        }
    }
});

export {blueDarkTheme};