import DataPage from '../wrappers/DataPage'
import BigCalendar from '../bigCalendar/BigCalendar'


export default function CalendarPage() {

    return (
        <DataPage
            Content={<BigCalendar />}
            title={"Calendar"}
        />
    )
}