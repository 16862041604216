import {useState, Fragment, useContext, useEffect, useMemo, useCallback} from 'react'

import {DataGrid} from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { DeleteForever, Edit, Info } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import {UserContext} from '../../contexts'
import { useGetProducts } from '../../hooks/queries'
import DataField from '../DataField'
import DataList from '../DataList'
import ProductInfo from '../modals/ProductInfo'
import ModalContainer from "../modals/ModalContainer";
import { ReplaceContent } from "../Transitions";
import AddEditProductForm from "../forms/AddEditProductForm";
import { useDeleteProduct } from "../../hooks/mutations/productMutations";

export default function ProductsTable() {

  console.log("Rendering ProductsTable");
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);

  let user = useContext(UserContext)

  const {isLoading, isError, data} = useGetProducts(user)

  const fields = useMemo(() => {return [
    "inProduction",
    "forSale",
    "unitPrice",
    "numberOfMolds",
  ]},[])

    const columns = useMemo(() => {return [
      {
        field: "name",
        headerName: "Name",
        minWidth: "100",
        flex: 1,
      },
      // {
      //     field: "code",
      //     headerName: "Code"
      // },
      {
        field: "type",
        headerName: "Type",
        minWidth: "100",
        flex: 1,
        valueGetter: (params) => {
          let typeName = params.row.type?.name;
          return typeName === undefined ? "" : `${params.row.type?.name}`;
        }
      },
      // {
      //     field: "mpn",
      //     headerName: "MPN"
      // },
      // {
      //     field: "unitPrice",
      //     headerName: "Price"
      // },
      // {
      //     field: "numberOfMolds",
      //     headerName: "Number of Molds"
      // },
      // {
      //     field: "productionEfficiency",
      //     headerName: "Production Efficiency"
      // },
      // {
      //     field: "molds",
      //     headerName: "Molds"
      // },
      // {
      //     field: "inProduction",
      //     headerName: "In Production?",
      //     width: "150",
      //     valueGetter: (params) => {
      //         if (params.row.inProduction) {
      //             return "	✅"
      //         } else {
      //             return "❌"
      //         }
      //     }
      // },
      // {
      //     field: "forSale",
      //     headerName: "For Sale?",
      //     valueGetter: (params) => {
      //         if (params.row.forSale) {
      //             return "	✅"
      //         } else {
      //             return "❌"
      //         }
      //     }
      // },
      {
        field: "inProgress",
        headerName: "In Progress",
        minWidth: "50",
        flex: 1,
        // width: 100,
        // editable: true,
        valueGetter: (params) => {
          let data = params.row.inventory && params.row.inventory[0].inProgress > 0
            ? JSON.stringify(params.row.inventory[0].inProgress)
            : "0";
          if (params.row.inventory && params.row.inventory[0].predictedInProgress > 0) {
            data = data + ` + ${params.row.inventory[0].predictedInProgress} predicted`
          }
          return data;
        },
      },
      {
        field: "inventory",
        headerName: "Completed",
        minWidth: "50",
        flex: 1,
        // width: 100,
        // editable: true,
        valueGetter: (params) => {
          let data =
            params.row.inventory && params.row.inventory[0].completed > 0
              ? JSON.stringify(params.row.inventory[0].completed)
              : "0";
          return data;
        },
      },
      {
        field: "Action",
        headerName: "",
        // type: 'actions', //need to add getActions function to use this
        minWidth: "150",
        align: "center",
        // getActions: (params) => {ProductTableButtons(params, user)}
        renderCell: (params) => ProductTableButtons(params, user),
      },
    ]},[])

    if (isLoading) {return <ReplaceContent content="Loading..." />;}
    if (isError) {return <ReplaceContent content="Error loading. Please refresh page." />;}

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          alignItems: "center",
          justifyContent: "center",
          margin: "1.5rem 5rem",
          // border: "2px solid",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: 20,
            display: "flex",
            justifyContent: "flex-end",
            // border: "2px solid",
          }}
        >
          <Button variant="contained" onClick={() => setAddProductModalOpen(true)}>
            Add product
          </Button>
          <Modal
            open={addProductModalOpen}
            onClose={() => setAddProductModalOpen(false)}
          >
            <ModalContainer>
              <AddEditProductForm setModalOpen={setAddProductModalOpen} />
            </ModalContainer>
          </Modal>
        </div>
        <div
          style={{
            width: "100%",
            // border: "2px solid"
          }}
        >
          <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            row
            rows={data}
            getRowId={(row) => row._id}
            columns={columns}
            onRowsScrollEnd
            density="compact"
            // resizable
            sx={{
              backgroundColor: "#333333",
            }}
          />
        </div>
      </div>
    );
}

function ProductTableButtons(params, user) {
  const [modalOpenLocal, setModalOpenLocal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  // const { mutate: mutateDeleteProduct } = useDeleteProduct();

  return (
    [
      <Fragment>
        <IconButton
        onClick={() => {setModalOpenLocal(true)}}
        color="info"
        sx={{ margin: "15px" }}
      >
        <Info />
      </IconButton>
      <Modal open={modalOpenLocal} onClose={() => setModalOpenLocal(false)}>
        <ModalContainer>
          <ProductInfo
            data={{
              title: params.row.name,
              code: params.row.code,
              mpn: params.row.mpn,
              type: params.row.type.name,
              maxPerDay: params.row.type.maxPerDay,
              inProduction: params.row.inProduction,
              forSale: params.row.forSale,
              numberOfMolds: params.row.numberOfMolds,
              updatedDate: params.row.updatedTimeStamp,
              addedDate: params.row.addedTimeStamp,
              inventory: params.row.inventory,
            }}
          />
        </ModalContainer>
      </Modal>
    </Fragment>,

    <Fragment>
      <IconButton
        onClick={() => {setEditModalOpen(true)}}
        color="warning"
        sx={{ margin: "15px" }}
      >
        <Edit />
      </IconButton>
      <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <ModalContainer>
          <AddEditProductForm product={params.row} setModalOpen={setEditModalOpen}/>
        </ModalContainer>
      </Modal>
    </Fragment>,
    
    ]
  );
}

      /* <IconButton
        onClick={() => {mutateDeleteProduct({user: user, id: params.row._id})}}
        color="error"
        sx={{ margin: "15px" }}
      >
        <DeleteForever />
      </IconButton> */