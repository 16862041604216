import { useState, useContext, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { UserContext, ModalControlContext } from "../../contexts";
import OrderCard from "./OrderCard";
import {ReplaceContent, CoverContent} from '../Transitions'
import { useGetOrders } from "../../hooks/queries";
import { useRerankOrders } from "../../hooks/mutations";

export default function OrdersCards() {
  const user = useContext(UserContext);
  const { mutate: mutateRerankOrders } = useRerankOrders();
  const [recalculating, setRecalculating] = useState(false);

  const {
    isLoading: ordersLoading,
    isError: ordersError,
    data: orders,
  } = useGetOrders(user);

  async function handleSelectChange(e, order) {
    mutateRerankOrders({ user, order, newPriority: e.target.value, setRecalculating });
  }

  function OrderRankSelect({ order, position, length }) {
    return (
      <FormControl
        fullWidth
        size="small"
        hiddenLabel={true}
        sx={{
          mt: 1,
          display: "flex",
          // alignItems: "stretch",
        }}
      >
        {/* <InputLabel id="label">{position + 1}</InputLabel> */}
        <Select
          autoWidth
          labelId="label"
          id="select"
          // value={position}
          // label="Add Product"
          onChange={(e) => handleSelectChange(e, order)}
        >
          {[...Array(length).keys()].map((rank) => {
            if (rank === position) {
              return (
                <MenuItem
                  key={order._id + rank}
                  // value={rank + 1}
                  sx={{
                    color: "#858585",
                  }}
                >
                  {rank + 1}
                </MenuItem>
              );
            }
            return (
              <MenuItem key={order._id + rank} value={rank + 1}>
                {rank + 1}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  if (ordersLoading) {
    return <ReplaceContent content="Loading..." />;
  }
  if (ordersError) {
    return <ReplaceContent content="Error loading. Please refresh page." />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        padding: "5px",
        position: "relative",
      }}
    >
      {recalculating ? <CoverContent content="Recalculating..." /> : ""}
      {orders.map((order, index) => {
        return (
          <OrderCard
            key={order._id}
            order={order}
            rankSelect={
              <OrderRankSelect
                order={order}
                position={index}
                length={orders.length}
              />
            }
          />
        );
      })}
    </div>
  );
}
