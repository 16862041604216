import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {Chip} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

import styles from "./ProductInfo.module.css";

export default function ProductInfo({ data }) {

    // console.log('data.inventory')
    // console.log(data.inventory[0])
    // console.log(data.inventory[0]._id)

    const rows = data.inventory.map((arr) => ({id: arr._id, ...arr})) //change id value in datagrid component
    // console.log('data.rows[0]')
    // console.log(rows[0])

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'color', headerName: 'Color', width: 70 },
        { field: 'inProgress', headerName: 'In Progress', width: 130 },
        { field: 'completed', headerName: 'Completed', width: 130 },
        { field: 'predictedProgress', headerName: 'Predicted Progress', width: 130 },
    ];

    return (
        <div className={styles["modal-container"]}>
          <Typography variant="h3" className={styles["title-typography"]}>
            {data.title}
          </Typography>
          <div className={styles["box-container"]}>
            <Box className={styles["left-box"]}>
              <table>
                <tbody>
                  <tr hidden={data.code === ""}>
                    <td>
                      <Typography>Code:</Typography>
                    </td>
                    <td>
                      <Chip label={data.code} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Type:</Typography>
                    </td>
                    <td>
                      <Chip label={data.type} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Max Per Day:</Typography>
                    </td>
                    <td>
                      <Chip label={data.maxPerDay} />
                    </td>
                  </tr>
                  <tr hidden={data.mpn === ""}>
                    <td>
                      <Typography>MPN:</Typography>
                    </td>
                    <td>
                      <Chip label={data.mpn} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Status:</Typography>
                    </td>
                    <td>
                      <Chip
                        label={
                          data.inProduction
                            ? "IN PRODUCTION"
                            : "NOT IN PRODUCTION"
                        }
                        style={{
                          color: data.inProduction ? "greenyellow" : "red",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Availability:</Typography>
                    </td>
                    <td>
                      <Chip
                        label={data.forSale ? "FOR SALE" : "INTERNAL"}
                        // className={data.forSale ? styles['chip-success'] : styles['chip-error']}
                        style={{
                          color: data.inProduction ? "greenyellow" : "red",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Number of Molds:</Typography>
                    </td>
                    <td>
                      <Chip label={data.numberOfMolds} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Last updated:</Typography>
                    </td>
                    <td>
                      <Chip label={Date(data.updatedDate)} />
                    </td>
                  </tr>
                  <tr>
                    <td className={styles["title-cell"]}>
                      <Typography variant="h5">Inventory</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style={{ height: "auto", width: "80%" }}>
                <DataGrid
                  sx={{ height: "10.2rem" }}
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
            </Box>
            {/*<Box className={styles['right-box']}>*/}

            {/*</Box>*/}
          </div>
        </div>
    );


}