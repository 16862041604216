import DataPage from '../wrappers/DataPage'
import ProductsTable from '../tables/ProductsTable'


export default function ProductsPage() {

    return (
        <DataPage
            Content={<ProductsTable/>}               
            title={"Products"}
        />
    )
}