import { useQueryClient, useMutation } from "react-query";
import { cacheKeys } from "../../enums/cacheKeys";
import { cancelQueriesAndSaveData } from "./commonMutationFunctions";


export function useRecountInventory() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLPASTDATES;
  const cachesToInvalidate = [
    cacheKeys.ALLFUTUREDATES,
    cacheKeys.ALLORDERS,
    cacheKeys.FOILSCHEDULE,
    cacheKeys.ALLPRODUCTS,
  ];

  return useMutation(
    async ({ user, pastDate, departments }) => {
      if (user) {
        await fetch("/api/pastDates/daily-prod/" + pastDate._id, {
          method: "PUT",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(departments),
        });
        return;
      }
    },
    // {
    //   onMutate: async ({ pastDate, departments }) => {
    //     console.log({ pastDate, departments });
    //     let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
    //     //update pastDate
    //     for (let deptUpdate of departments) {
    //       let pastDateDept = pastDate.departments.find((dept) => {
    //         return dept.department.name === deptUpdate.department.name;
    //       });
    //       if (!pastDateDept) {
    //         continue;
    //       }
    //       if (!deptUpdate.isWorking) {
    //         pastDateDept.isWorking = false;
    //         continue;
    //       }
    //       pastDateDept.isReported = true;
    //       if (deptUpdate.isForgotCount) {
    //         pastDateDept.isForgotCount = true;
    //         continue;
    //       }
    //       pastDateDept.actual = deptUpdate.actual;
    //     }
    //     console.log(
    //       "reported",
    //       pastDate.departments[0].isReported,
    //       pastDate.departments[1].isReported
    //     );
    //     return context;
    //   },
    //   onError: (err, input, context) => {
    //     queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
    //   },
    // }
  );
}
