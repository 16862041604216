import { useState, useContext } from 'react'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import { useGetProducts } from '../../hooks/queries'
import { useReportCount } from "../../hooks/mutations"
import { UserContext, ModalControlContext } from "../../contexts";
import { ReplaceContent } from "../Transitions";

export default function ProductQtyEntry(pastDate, dept, title, startingEntries = []) {
    
    const user = useContext(UserContext)

    let { setModalOpen } = useContext(ModalControlContext);

    const [qtyEntries, setQtyEntries] = useState(dept?.scheduled?.products|| []);
    const [noCount, setNoCount] = useState(false);

    console.log(qtyEntries)

    const { mutate: mutateReportCount } = useReportCount();
    const { isLoading: productsLoading, isError: productsError, data: products } = useGetProducts(user);
   
    function handleProductQtyChange2(e) {
        if (e.target.value >= 0 || e.target.value === "") {
            setQtyEntries(oldEntries => {
                let newEntries = [...oldEntries]
                let entryIndexToUpdate = oldEntries.findIndex(oldEntry => { return (oldEntry.product._id === e.target.name)})
                let newEntry = newEntries[entryIndexToUpdate]
                newEntry.inputValue = e.target.value
                newEntries.splice(entryIndexToUpdate,1,newEntry)
                return (newEntries)
            })
        } else {
            //TODO: trigger error message on TextInput
        }
    }

    function handleSelectChange2(e) {
        setQtyEntries(old => { return ([...old, {product: e.target.value, inputValue: ""}]) })
    }

    function submit(e, pastDate, dept) {
        e.preventDefault();
        let products = [];
        qtyEntries.forEach(qtyEntry => {
            if ("inputValue" in qtyEntry && qtyEntry.inputValue !== "" && qtyEntry.inputValue !== "0") {
                products.push({
                    product: qtyEntry.product,
                    quantity: parseInt(qtyEntry.inputValue)
                })
            }
        })

        let deptObj = {
          department: { name: dept.department.name },
          isWorking: true,
          actual: { products: products },
          isReported: true,
        };

        if (products.length === 0){
            deptObj["isForgotCount"] = true
        } else {
            deptObj["isForgotCount"] = false;
        }

        mutateReportCount({ user, pastDate, departments: [deptObj]})
    }

    if (productsLoading) { return <ReplaceContent content="Loading..." />; }
    if (productsError) { return <ReplaceContent content="Error loading. Please refresh page." />; }

    return (
        <form
          onSubmit={(e) => {
            submit(e, pastDate, dept);
            setModalOpen(false);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>{title + " (scheduled)"}</Typography>
          <FormControl
            fullWidth
            size="small"
            sx={{
              mt: 1,
              display: "flex",
              alignItems: "stretch",
            }}
          >
            {" "}
            {/*TODO: implement product type selector, which activated product selector which only shows products of the selected type*/}
            <InputLabel id="label">Add Product</InputLabel>
            <Select
              autoWidth
              labelId="label"
              id="select"
              value=""
              label="Add Product"
              onChange={handleSelectChange2}
            >
              {products.reduce((result, product) => {
                if (
                  !qtyEntries.find(
                    (qtyEntry) => qtyEntry.product._id === product._id
                  )
                ) {
                  result.push(
                    <MenuItem key={product._id} value={product}>
                      {product.name}
                    </MenuItem>
                  );
                }
                return result;
              }, [])}
            </Select>
          </FormControl>
                <Paper
        sx={{
          minWidth: 200,
          maxHeight: "50vh",
          overflow: "auto",
          flex: 1,
        }}
      >
          {qtyEntries.map((qtyEntry) => {
            return (
              <div
                key={qtyEntry.product._id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <TextField
                  aria-label={qtyEntry.product.name + " field"}
                  key={qtyEntry.product._id}
                  name={qtyEntry.product._id}
                  size="small"
                  color="primary"
                  label={qtyEntry.product.name}
                  placeholder={qtyEntry.product.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                  onChange={handleProductQtyChange2}
                  value={qtyEntry.inputValue}
                  sx={{
                    width: "15ch",
                  }}
                />
                <Typography
                  sx={{
                    marginLeft: 2,
                    width: 15,
                  }}
                >
                  {qtyEntry.quantity ? `(${qtyEntry.quantity})` : null}
                </Typography>
              </div>
            );
          })}
          </Paper>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{
              m: 2,
            }}
          >
            Submit
          </Button>
        </form>
    );
}