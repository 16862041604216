import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText';
import {Link} from 'react-router-dom'
import LoginForm from './forms/LoginForm'
import titleLogo from '../media/logo_vertical_2.png'

const drawerWidth = "32ch";

function NavBar({
    user,
    setUser,
    decodedUser
}) {

    let links = [
        ['Home', '/', false],
        ["Products", '/products', 'SUPER_ADMIN'],
        ["Orders", '/orders', 'SUPER_ADMIN'],
        ["Add Order", "/addOrder", "SUPER_ADMIN"],
        ['Calendar', '/calendar', 'SUPER_ADMIN'],
        // ['Reports', '/reports', 'SUPER_ADMIN'],
        //['Change Working Dates', '/productionDates', 'admin'],
        //["Schedule", '/schedule', 'admin'], 
        //['Record Daily Production', '/recordProduction', 'admin']
    ]
    
    return (
        <Drawer 
            variant="permanent"
            anchor="left"
            sx={{
                display: "flex",
                flexDirection:"column",
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                }
            }}
        >
            <img
                src={titleLogo}
                alt="GoFoil Logo"
                style={{
                    // position: 'relative',
                    // top: 7,
                    // left: 0,
                    // width: "100%",
                    margin: 3,
                    marginTop: 50
                }}
            />
            <List
                sx={{
                    // border: "2px solid white",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "stretch",
                    marginTop: "60px",
                    marginBottom: "60px"
                }}
            >
                {links.map((i) => {
                    // console.log(decodedUser);
                    if (
                        i[2] === false || 
                        (decodedUser.roles?.some(role => role.roleName === i[2]))
                    ) {//rewrite to check for intersection of roles and i[2] array
                        return (
                            <ListItemButton 
                                component={Link} 
                                to={i[1]} 
                                underline="none" 
                                key={i[1]}
                                sx={{
                                    // border: "2px solid grey",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "stretch",
                                    textAlign: "center",
                                    // height: "100px"
                                }}
                            >
                                <ListItemText 
                                    primary={i[0]}
                                    primaryTypographyProps={{ fontSize: '1.4em' }} 
                                    sx={{
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                />
                            </ListItemButton>
                        )
                    } 
                    return
                })}
            </List>
            <Divider/>
            <LoginForm user={user} setUser={setUser}/>


        </Drawer>
    )
}

export default NavBar;
