import {useState} from 'react'

import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'

import format from 'date-fns/format'

import ProductQtyEntry from '../forms/ProductQtyEntry'
import DataField from "../DataField";
import InventoryRecount from '../forms/InventoryRecount'

export default function PastDateInfo({
  pastDate,
  isToday  
}) {

    return (
      <div>
        <div
          style={{
            padding: 5,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              // height: "2em"
            }}
          >
            {format(pastDate.date, "PPPP")}
          </Typography>
          {/* <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        // height: "2em"
                    }}
                >
                    {format(date, "'Began at' hh:mmaaa")}
                </Typography> */}
        </div>
        {Departments(pastDate, isToday)}
      </div>
    );
}

function Departments(pastDate, isToday) {
    const [isRecount, setIsRecount] = useState(false);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-around",
          flex: 1,
        }}
      >
        {pastDate.departments?.map((dept) => {
          return department(pastDate, dept);
        })}
        {isToday ? 
          isRecount ? (
            <InventoryRecount pastDate={pastDate}/>
          ) : (
            <Button variant="contained" onClick={() => setIsRecount(true)}>
              Recount Inventory
            </Button>
        ) : null}
      </div>
    );
}

function department(pastDate, dept) {
    return (
        <div
            key={pastDate.date + dept.department.name}
        >
            <Typography
                variant="h5"
                sx={{
                    textAlign: "center"
                }}
            >
                {dept.department.name}
            </Typography>
            {deptState(pastDate, dept)}
        </div>
    )
}

function deptState(pastDate, dept) {
    if (!dept.isWorking) {
        return <Typography>Closed this day</Typography>
    } else if (dept.isReported) {
        if (dept.isForgotCount) {
            return <Typography>No count made</Typography>
        } else {
            return (
              <div>
                <Typography>Department reported</Typography>
                {console.log(dept)}
                {dept.actual.products.map(productEntry => 
                    {return (
                        <DataField
                            key={productEntry.product._id}
                            field={productEntry.product.name}
                            data={productEntry.quantity}
                        />
                    )}
                )}
              </div>
            );
        }
    } else if (dept.department.name === "foilStart") {
        return (
            <div
                style={{
                    display: "flex",
                    // flexDirection: "column",

                }}
            >
                {ProductQtyEntry(pastDate, dept, "Report started")}
            </div>
        )
    } else {
        return (
            <div>
                {ProductQtyEntry(pastDate, dept, "Report completed:")}
            </div>
        )
    }
}

//productQuantityList(dept.scheduled.products)

// function productQuantityList(array) {
//     return (
//         <div
//             style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center"
//             }}
//         >
//             <Typography>
//                 Scheduled:
//             </Typography>
//             <List>
//                 {array.map(p => {
//                     return (
//                         <ListItem disablePadding
//                             key={p.product._id}
//                             sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 alignItems: "flex-start"
//                             }}
//                         >
//                             <ListItemText primary={`${p.product.name} ---- ${p.quantity}`} />
//                         </ListItem>
//                     )
//                 })
//                 }
//             </List>
//         </div>
//     )
// }