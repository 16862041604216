import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function DataField({
    field, 
    data
}){
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // border: "2px solid",
                // flex: 1,
            }}
        >
            <Typography
                sx={{
                    // width: "40%",
                    minWidth: "300",
                    // textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    // border: "2px solid blue"
                }}
            >
                {field}
            </Typography>
            <Box
                sx={{
                    // flex: 1,
                    display: 'flex',
                    padding: 1,
                    paddingRight: 3,
                    paddingLeft: 3,
                    m: 1,
                    bgcolor: "#4f4f4f",//(theme) => theme.palette.neutral.dark,
                    // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    // border: '1px solid',
                    // borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                    borderRadius: 2,
                    fontSize: '1rem',
                            // fontWeight: '700',
                }}
            >
                {data}
            </Box>
        </div>
    )
}

