import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import Home from './pages/HomePage'
import NavBar from './NavBar'
import AppBar from './AppBar'
import Box from '@mui/material/Box'
import ProtectedRoute from './wrappers/ProtectedRoute'
import ProductsPage from './pages/ProductsPage'
import OrdersPage from './pages/OrdersPage'
import CalendarPage from './pages/CalendarPage'
import AddOrderPage from './pages/AddOrderPage'
import GlobalModal from './GlobalModal'
import Login from "./pages/Login";
import InvoicePage from "./pages/InvoicePage";


// import userState from './UserState';

function AppRoutes({
    user,
    setUser,
    decodedUser
}) {
    const HideNavBar = () => {
        if (useLocation().pathname.match(/login/)) {
            return null;
        }

        return (
            <NavBar
                user={user}
                setUser={setUser}
                decodedUser={decodedUser}
            />
        )
    }
    const HideAppBar = () => {
        if (useLocation().pathname.match(/login/)) {
            return null;
        }

        return (
            <AppBar/>
        )
    }


    return (
        <Router>
            {/* <HideAppBar/> */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: "stretch",
                    minHeight: "100vh",
                }}>

                <HideNavBar/>

                <GlobalModal/>
                {/* <Suspense fallback={<div>Page is loading...</div>}> */}
                <Routes>
                    <Route exact path='/' element={<Home/>}/>
                    <Route path='/login' element={<Login
                        user={user}
                        setUser={setUser}
                    />}/>
                    <Route path="/products" element={
                        <ProtectedRoute
                            redirect='/'
                            Passthrough={<ProductsPage/>}
                        />
                    }/>
                    <Route path="/orders" element={
                        <ProtectedRoute
                            redirect='/'
                            Passthrough={<OrdersPage/>}
                        />
                    }/>
                    <Route path="/addOrder" element={
                        <ProtectedRoute
                            redirect='/'
                            Passthrough={<AddOrderPage/>}
                        />
                    }/>
                    {/* <Route path="/recordProduction" element={
                            <ProtectedRoute
                                redirect='/'
                                Passthrough={
                                    <DataPage
                                        Content={RecordProduction}
                                        user={user}
                                        title={"Record Production"}
                                    />
                                }
                            />
                        } /> */}
                    <Route path="/calendar" element={
                        <ProtectedRoute
                            redirect='/'
                            Passthrough={<CalendarPage/>}
                        />
                    }/>
                    {/* <Route path="/invoice" element={
                        <ProtectedRoute
                            redirect='/'
                            Passthrough={<InvoicePage/>}
                        />
                    }/> */}
                    {/*<Route path="/*" element={*/}
                    {/*    404*/}
                    {/*/>*/}
                </Routes>
                {/* </Suspense> */}
            </Box>
        </Router>
    );
}

export default AppRoutes;
