import { useQueryClient, useMutation } from "react-query";
import compareAsc from "date-fns/compareAsc";
import isSameDay from 'date-fns/isSameDay'
import { cacheKeys } from "../enums/cacheKeys";
import { cancelQueriesAndSaveData } from "./mutations/commonMutationFunctions";

export function useAddDate() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLFUTUREDATES;
  const cachesToInvalidate = [cacheKeys.ALLFUTUREDATES, cacheKeys.ALLORDERS];

  return useMutation(
    async ({ user, date }) => {
      if (user) {
        let response = await fetch("/api/futureDates", {
          method: "PUT",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ date }),
        });
        return response.json();
      }
    },
    {
      onMutate: async ({ date }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        queryClient.setQueryData(cacheToMutate, (oldData) => {
          return [...oldData, { date: date }].sort((a, b) =>
            compareAsc(a.date, b.date)
          );
        });
        return context;
      },
      onError: (err, input, context) => {
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
      onSuccess: (newFutureDate) => {
        newFutureDate.date = new Date(newFutureDate.date)
        let futureDates = queryClient.getQueryData(cacheToMutate)
          let mutatedFutureDateIndex = -1;
        for (let index = futureDates.length - 1; index >= 0; index-- ){
          if(isSameDay(futureDates[index].date, newFutureDate.date)){
              mutatedFutureDateIndex = index
              break
          }
        }
        if (mutatedFutureDateIndex > -1){
          futureDates.splice(mutatedFutureDateIndex, 1, newFutureDate);
        }
      }
    }
  );
}

export function useRemoveDate() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLFUTUREDATES;
  const cachesToInvalidate = [cacheKeys.ALLFUTUREDATES, cacheKeys.ALLORDERS];

  return useMutation(
    async ({ user, dateToRemove }) => {
      if (user) {
        console.log("_id", dateToRemove._id);
        await fetch("/api/futureDates/" + dateToRemove._id, {
          method: "DELETE",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
        });
        return;
      }
    },
    {
      onMutate: async ({ dateToRemove }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        queryClient.setQueryData(cacheToMutate, (oldData) => {
          return oldData.filter((oldDate) => {
            return oldDate._id !== dateToRemove._id;
          });
        });
        return context;
      },
      onError: (err, input, context) => {
        console.log("error", err);
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
    }
  );
}

export function useReportCount() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLPASTDATES;
  const cachesToInvalidate = [
    cacheKeys.ALLFUTUREDATES,
    cacheKeys.ALLORDERS,
    cacheKeys.FOILSCHEDULE,
    cacheKeys.ALLPRODUCTS,
  ];

  return useMutation(
    async ({ user, pastDate, departments }) => {
      if (user) {
        await fetch("/api/pastDates/daily-prod/" + pastDate._id, {
          method: "POST",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(departments),
        });
        return;
      }
    },
    {
      onMutate: async ({ pastDate, departments }) => {
        console.log({ pastDate, departments });
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        //update pastDate
        for (let deptUpdate of departments) {
          let pastDateDept = pastDate.departments.find((dept) => {
            return dept.department.name === deptUpdate.department.name;
          });
          if (!pastDateDept) {
            continue;
          }
          if (!deptUpdate.isWorking) {
            pastDateDept.isWorking = false;
            continue;
          }
          pastDateDept.isReported = true;
          if (deptUpdate.isForgotCount) {
            pastDateDept.isForgotCount = true;
            continue;
          }
          pastDateDept.actual = deptUpdate.actual;
        }
        console.log(
          "reported",
          pastDate.departments[0].isReported,
          pastDate.departments[1].isReported
        );
        return context;
      },
      onError: (err, input, context) => {
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
    }
  );
}

export function useAddOrder() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLORDERS;
  const cachesToInvalidate = [cacheKeys.ALLORDERS, cacheKeys.FOILSCHEDULE];

  return useMutation(
    async ({ user, order }) => {
      if (user) {
        let response = await fetch("/api/orders", {
          method: "POST",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(order),
        });
        return response.json();
      }
    },
    {
      onMutate: async ({ order }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        if (queryClient.getQueryData(cacheToMutate)) {
          console.log(queryClient.getQueryData(cacheToMutate));
          queryClient.setQueryData(cacheToMutate, (oldData) => {
            return [...oldData, order];
          });
        }
        return context;
      },
      onError: (err, input, context) => {
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
      onSuccess: (data) => {
        queryClient.setQueryData(cacheToMutate, data);
      },
    }
  );
}




export function useRerankOrders() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLORDERS;
  const cachesToInvalidate = [cacheKeys.ALLORDERS, cacheKeys.FOILSCHEDULE];

  return useMutation(
    async ({ user, order, newPriority, setRecalculating }) => {
      console.log({ user, order, newPriority });
      if (user) {
        let response = await fetch("/api/orders/" + order._id, {
          method: "POST",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newPriority }),
        });
        return response.json();
      }
    },
    {
      onMutate: async (input) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        input.setRecalculating(true);
        // queryClient.setQueryData(cacheToMutate, (oldData) => {
        // return [...oldData, order];
        // });
        return context;
      },
      onError: (err, input, context) => {
        input.setRecalculating(false);
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
      onSuccess: (data, input) => {
        input.setRecalculating(false);
        queryClient.setQueryData(cacheToMutate, data);
      },
    }
  );
}