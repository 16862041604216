import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import styles from "./OrderInfo.module.css";

export default function OrderInfo({ data }) {

    const columns = [
      { field: "name", headerName: "Name", valueGetter: (params) => {return params.row.product.name}, width: 150 },
      { field: "quantity", headerName: "Quantity"},
      { field: "inProgress", headerName: "In Progress"},
      { field: "completed", headerName: "Completed"},
    ];

  return (
    <div className={styles["modal-container"]}>
      <Typography variant="h3" className={styles["title-typography"]}>
        {data.name}
      </Typography>
      <div className={styles["box-container"]}>
        <Box className={styles["left-box"]}>
          <table>
            <tbody>
              <tr hidden={data.priorityWeight === ""}>
                <td>
                  <Typography>Priority:</Typography>
                </td>
                <td>
                  <Chip label={data.priorityWeight} />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>Type:</Typography>
                </td>
                <td>
                  <Chip label={data.type} />
                </td>
              </tr>
              {/* <tr hidden={data.customer == undefined}>
                <td>
                  <Typography>Customer:</Typography>
                </td>
                <td>
                  <Chip label={JSON.stringify(data.customer)} />
                </td>
              </tr> */}
              <tr>
                <td>
                  <Typography>Last updated:</Typography>
                </td>
                <td>
                  <Chip label={Date(data.updatedAt)} />
                </td>
              </tr>
              {/* <tr>
                <td className={styles["title-cell"]}></td>
              </tr> */}
            </tbody>
          </table>
        </Box>
        <Box className={styles["right-box"]}>
            <Typography variant="h5">Products</Typography>
            <DataGrid
              // sx={{ height: "auto" }}
              autoheight
              rows={data.products}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
        </Box>
      </div>
    </div>
  );
}
