import {
  useState,
  useEffect,
  useContext,
  cloneElement,
  useMemo,
  useCallback,
} from "react";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
// import 'react-big-calendar/lib/css/react-big-calendar.css'
import "../../styling/bigCalendar.css";

import ScheduleInfo from "./ScheduleInfo";
import PastDateInfo from "./PastDateInfo";

import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import isSameDay from "date-fns/isSameDay";
import isAfter from "date-fns/isAfter";
import addDays from 'date-fns/addDays'
import set from "date-fns/set";
import differenceInHours from "date-fns/differenceInHours";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Modal from "@mui/material/Modal";

import { UserContext, ModalControlContext } from "../../contexts";
import {
  useGetFutureDates,
  useGetSchedule,
  useGetPastDates,
} from "../../hooks/queries";
import { useAddDate, useRemoveDate } from "../../hooks/mutations";

export default function BigCalendar() {
  console.log("rerendering big calendar");

  let user = useContext(UserContext);
  let { modalOpen, setModalOpen, setModalContent } =
    useContext(ModalControlContext);

  const { mutate: mutateAddDate } = useAddDate();
  const { mutate: mutateRemoveDate } = useRemoveDate();

  const [modalVisible, setModalVisible] = useState(false);

  const theme = useTheme();

  const {
    // isLoading: futureDatesLoading,
    // isError: futureDatesError,
    // isSuccess: futureDatesSuccess,
    data: futureDates,
  } = useGetFutureDates(user);
  const {
    // isLoading: pastDatesLoading,
    // isError: pastDatesError,
    // isSuccess: pastDatesSuccess,
    data: pastDates,
  } = useGetPastDates(user);
  const {
    // isLoading: foilScheduleLoading,
    // isError: foilScheduleError,
    // isSuccess: foilScheduleSuccess,
    data: foilSchedule,
  } = useGetSchedule(user);

  let now = new Date();

  // console.log({futureDatesLoading, futureDatesError, futureDatesSuccess, futureDates})
  // console.log({ pastDatesLoading, pastDatesError, pastDatesSuccess, pastDates })
  // console.log({ foilScheduleLoading, foilScheduleError, foilScheduleSuccess, foilSchedule })

  // useEffect(() => {
  //     console.log(futureDates)
  // }, [futureDates])

  const style = useMemo(() => {
    return {
      height: "90vh",
      minWidth: "1000px",
    };
  }, []);

  const locales = useMemo(() => {
    return { "en-US": enUS };
  }, []);

  const localizer = useMemo(
    () =>
      dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
      }),
    [locales]
  );

  const allDay = useMemo(() => {
    return () => true;
  }, []);

  // function selectSlot(e) {
  //     if (e.slots?.length > 1 || e.start <= new Date()) {
  //         return
  //     } else {
  //         alert(`Make ${JSON.stringify(e.start)} a working day?`)
  //     }
  // }

  const modifiedDate = useCallback((e, children) => {
    if (children) {
      return cloneElement(
        e.children,
        {
          key: e.value,
          style: {
            display: "flex",
            justifyContent: "stretch",
            alignItems: "stretch",
          },
        },
        [children]
      );
    }
    return e.children;
  }, []);

  const addDate = useCallback(
    (dateSelected, futureDates) => {
      //sets the time of the new date to the same time as other dates (3am Weihai time) no matter the browser time
      let zeroedDate = set(futureDates[0].date, {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      let diff = differenceInHours(futureDates[0].date, zeroedDate);
      let finalDate = set(dateSelected, { hours: diff });
      mutateAddDate({
        user: user,
        date: finalDate,
      });
    },
    [user, mutateAddDate]
  );

  const makeFutureScheduledDay = useCallback(
    (dateInfo, foundIndex, futureDate) => {
      let scheduleDay;
      if (foilSchedule) {
        if (foilSchedule.schedule.length > foundIndex) {
          scheduleDay = foilSchedule.schedule[foundIndex];
        } else {
          scheduleDay = false;
        }
      }
      return modifiedDate(
        dateInfo,
        <div
          key={dateInfo.value}
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "stretch",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {scheduleDay ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setModalContent(ScheduleInfo(futureDate, scheduleDay));
                  setModalOpen(true);
                }}
                sx={{
                  margin: "5px",
                }}
              >
                Schedule
              </Button>
            ) : scheduleDay === false ? (
              modifiedDate(
                dateInfo,
                <Typography sx={{ textAlign: "center" }}>
                  No schedule yet
                </Typography>
              )
            ) : (
              modifiedDate(dateInfo)
            )}
          </div>
          {"_id" in futureDate ? (
            <Button
              // variant="contained"
              color="neutral"
              size="small"
              onClick={() =>
                mutateRemoveDate({
                  user: user,
                  dateToRemove: futureDate,
                })
              }
              sx={{
                margin: "10px",
              }}
            >
              Remove
            </Button>
          ) : null}
        </div>
      );
    },
    [
      foilSchedule,
      user,
      modifiedDate,
      mutateRemoveDate,
      setModalContent,
      setModalOpen,
    ]
  );

  const makeFutureNoScheduleDay = useCallback(
    (dateInfo) => {
      return modifiedDate(
        dateInfo,
        <div
          key={dateInfo.value}
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "stretch",
          }}
        >
          <Button
            // variant="contained"
            color="activate"
            size="small"
            onClick={() => addDate(dateInfo.value, futureDates)}
            sx={{
              margin: "10px",
            }}
          >
            Add
          </Button>
        </div>
      );
    },
    [futureDates, addDate, modifiedDate]
  );

  const makeFutureDate = useCallback(
    (dateInfo) => {
      if (futureDates) {
        let foundIndex = futureDates.findIndex((futureDate) => {
          return isSameDay(futureDate.date, dateInfo.value);
        });
        if (foundIndex > -1) {
          let foundFutureDate = futureDates[foundIndex];
          return makeFutureScheduledDay(dateInfo, foundIndex, foundFutureDate);
        }

        return makeFutureNoScheduleDay(dateInfo);
      }

      return modifiedDate(dateInfo);
    },
    [futureDates, modifiedDate, makeFutureScheduledDay, makeFutureNoScheduleDay]
  );

  const makePastOrPresentDate = useCallback(
    (dateInfo, isToday) => {
      if (!pastDates) {
        return modifiedDate(dateInfo);
      }
      let pastDate = pastDates.find((d) => {
        return isSameDay(d.date, dateInfo.value);
      });
      if (pastDate) {
        let allDeptsReported = pastDate.departments.every((department) => {
          return department.isReported === true;
        });
        return modifiedDate(
          dateInfo,
          <div
            key={dateInfo.value}
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              backgroundColor: !allDeptsReported ? "#824141" : "transparent",
              position: "relative",
            }}
          >
            {!allDeptsReported ? 
              <Typography
                sx={{
                  position: "absolute",
                  marginBottom: "19px",
                }}
              >
                NEEDS REPORT
              </Typography> : 
              null}
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border: "2px solid",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setModalContent(<PastDateInfo pastDate={pastDate} isToday={isToday}/>);
                  setModalOpen(true);
                }}
                sx={{
                  margin: "5px",
                }}
              >
                Data
              </Button>
            </div>
          </div>
        );
      } else {
        return modifiedDate(dateInfo);
      }
    },
    [pastDates, modifiedDate, setModalContent, setModalOpen]
  );

  const makeDate = useCallback(
    (e) => {
      let calendarDate = e.value.setUTCHours(19, 0, 0, 0);
      if (isAfter(calendarDate, now)) {
        return makeFutureDate(e);
      } else if (isAfter(calendarDate, addDays(now, -1))) {
        return makePastOrPresentDate(e, true);
      } else {
        return makePastOrPresentDate(e, false)
      }
    },
    [now, makeFutureDate, makePastOrPresentDate]
  );

  const components = useMemo(() => {
    return {
      dateCellWrapper: (e) => makeDate(e),
    };
  }, [makeDate]);

  return (
    <div>
      <Calendar
        localizer={localizer}
        views={["month"]}
        selectable={"false"}
        // onSelectSlot={selectSlot}
        allDayAccessor={allDay}
        components={components}
        style={style}
      />
    </div>
  );
}

// const customComponents = {
//     dateCellWrapper: (dateCellWrapperProps) => {
//         // Show 'click me' text in arbitrary places by using the range prop
//         const hasAlert = dateCellWrapperProps.range
//             ? dateCellWrapperProps.range.some((date) => {
//                 return date.getDate() % 12 === 0
//             })
//             : false

//         const style = {
//             display: 'flex',
//             flex: 1,
//             borderLeft: '1px solid #DDD',
//             backgroundColor: hasAlert ? '#f5f5dc' : '#fff',
//         }
//         return (
//             <div style={style}>
//                 {hasAlert && (
//                     <a onClick={action('custom dateCellWrapper component clicked')}>
//                         Click me
//                     </a>
//                 )}
//                 {dateCellWrapperProps.children}
//             </div>
//         )
//     }
// }
