import { useEffect, useContext } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

import { UserContext } from "../../contexts";
import { ReplaceContent } from "../Transitions";
import {useGetProductCategories} from '../../hooks/queries'

export default function ProductCategorySelect({
  category,
  setCategory,
}) {

  let user = useContext(UserContext);

  const { isLoading, isError, data: productCategories } = useGetProductCategories(user);

  function handleEntryChange(e, child) {
    console.log("change", child.props["data-category"]);
    setCategory(child.props["data-category"]);
  }

    if (isLoading) {
      return <ReplaceContent content="Loading..." />;
    }
    
    if (isError) {
      return <ReplaceContent content="Error loading. Please refresh page." />;
    }

  return (
    <FormControl
      sx={{minWidth: 200}}
    >
      <InputLabel>Product Category</InputLabel>
      <Select
        onChange={handleEntryChange}
        value={category.name}
        label="Product Category"
      >
        {productCategories.map(category => {
          return (
            <MenuItem
              key={category._id}
              value={category.name}
              data-category={category}
            >{category.name}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
