import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import loginRequest from "../../functions/requests/loginRequests";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// import { UserContext } from "../../contexts";

//TODO: configure error displays to user
//TODO: remove white background from autofill entries ala https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete

function LoginForm({ user, setUser }) {
  function initialValues() {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  }

  // let user = useContext(UserContext);

  const [values, setValues] = useState(initialValues());

  // LoginForm.propTypes = {
  //     setUser: PropTypes.func.isRequired
  // }

  async function login(e, val, setVal) {
    e.preventDefault();
    const response = await loginRequest({
      username: val.username,
      password: val.password,
    });
    if (response.jwt) {
      setUser(response);
      localStorage.setItem("token", JSON.stringify(response));
      setVal(initialValues());
    } else {
      console.log(response);
      // e.target.error = response.message;
    }
  }

  async function logout(e) {
    e.preventDefault();
    setUser(false);
    localStorage.removeItem("token");
    //TODO: delete saved database data
  }

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  if (user) {
    return (
      <form
        onSubmit={logout}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          type="submit"
          color="activate"
          variant="contained"
          sx={{
            m: 3,
          }}
        >
          Log out
        </Button>
      </form>
    );
  }

  return (
    <form
      onSubmit={(e) => login(e, values, setValues)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ m: 1 }}>Sign In</Typography>
      <TextField
        aria-label="username field"
        variant="outlined"
        required
        label="Username"
        onChange={handleChange("username")}
        value={values.username}
        autoComplete="username"
        sx={{
          m: 0.5,
          alignSelf: "stretch",
        }}
      />
      <TextField
        aria-label="password field"
        variant="outlined"
        color="primary"
        type={values.showPassword ? "text" : "password"}
        required
        label="Password"
        onChange={handleChange("password")}
        value={values.password}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          m: 0.5,
          alignSelf: "stretch",
        }}
      />
      <Button
        type="submit"
        color="activate"
        variant="contained"
        sx={{
          m: 2,
        }}
      >
        Login
      </Button>
    </form>
  );
}

export default LoginForm;
