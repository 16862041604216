async function loginRequest(credentials) {
    let response = await fetch('/api/auth/login', {
        method: "POST",
        headers: {
            'Content-Type': "application/json"
        },
        body: JSON.stringify(credentials)
    })
    return await response.json();
}

export default loginRequest;