import { useQueryClient, useMutation } from "react-query";
import { cacheKeys } from "../../enums/cacheKeys";
import { cancelQueriesAndSaveData } from "./commonMutationFunctions";

export function useAddProduct() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLPRODUCTS;
  const cachesToInvalidate = [cacheKeys.ALLPRODUCTS];

  return useMutation(
    async ({ user, product }) => {
      if (user) {
        let response = await fetch("/api/products", {
          method: "POST",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product),
        });
        return response.json();
      }
    },
    {
      onMutate: async ({ product }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        if (queryClient.getQueryData(cacheToMutate)) {
          queryClient.setQueryData(cacheToMutate, (oldData) => {
            let oldDataClone = [...oldData];
            let newProduct = { ...product, _id: "abc" };
            console.log({newProduct})
            oldDataClone.push(newProduct);
            return oldDataClone;
          });
        }
        return context;
      },
      onError: (err, input, context) => {
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
  //   onSuccess: (data) => {
  //   },
    }
  );
}

export function useUpdateProduct() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLPRODUCTS;
  const cachesToInvalidate = [cacheKeys.ALLPRODUCTS];

  return useMutation(
    async ({ user, product }) => {
      if (user) {
        let response = await fetch("/api/products/" + product._id, {
          method: "PUT",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product),
        });
        return response.json();
      }
    },
    {
      onMutate: async ({ product }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
        if (queryClient.getQueryData(cacheToMutate)) {
          queryClient.setQueryData(cacheToMutate, (oldData) => {
            let index = oldData.findIndex((item => item._id === product._id))
            console.log({index})
            let oldDataClone = [...oldData]
            oldDataClone.splice(index, 1, product)
            return oldDataClone;
          });
        }
        return context;
      },
      onError: (err, input, context) => {
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
    //   onSuccess: (data) => {
    //   },
    }
  );
}


export function useDeleteProduct() {
  const queryClient = useQueryClient();
  const cacheToMutate = cacheKeys.ALLPRODUCTS;
  const cachesToInvalidate = [cacheKeys.ALLPRODUCTS];

  return useMutation(
    async ({ user, id }) => {
      if (user) {
        fetch("/api/products/" + id, {
          method: "DELETE",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
        });
      }
    },
    {
      onMutate: async ({ id }) => {
        let context = cancelQueriesAndSaveData(cachesToInvalidate, queryClient);
          queryClient.setQueryData(cacheToMutate, (oldData) => {
          if (oldData === undefined){
            return undefined
          } else {
            let index = oldData.findIndex((item) => item._id === id);
            let oldDataClone = [...oldData];
            oldDataClone.splice(index,1);
            return oldDataClone;
          };
        })
        return context;
      },
      onError: (err, input, context) => {
        console.log({err})
        queryClient.setQueryData(cacheToMutate, context[cacheToMutate]);
      },
    }
  );
}