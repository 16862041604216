import Typography from '@mui/material/Typography'

function DataPage({
    Content,
    title,
}) {

    return (
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "stretch",
            }}
        >
            <Typography
                variant="h4"
                align="center"
                sx={{
                    margin: 3,
                }}
            >
                {title}
            </Typography>
            {Content}
        </div>
    )
}

export default DataPage;
