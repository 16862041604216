import { useState, useEffect, useContext, useMemo, useCallback } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import ProductCategorySelect from './ProductCategorySelect'
import MoldsSelect from "./MoldsSelect";

import { ReplaceContent } from "../Transitions";

import { UserContext } from "../../contexts";

import { useAddProduct, useUpdateProduct } from "../../hooks/mutations/productMutations";

export default function AddEditProductForm({
  product,
  setModalOpen
}) {

  console.log("Rendering AddEditProductForm")

  const emptyProduct = useMemo(() => {return {
    name: "",
    codename: "",
    mpn: "",
    unitPrice: "",
    notes: "",
    isPrototype: false,
    inProduction: false,
    forSale: false,
    type: undefined,
    numberOfMolds: 0,
    molds: [],
    productionEfficiency: 1,
  }},[])

  const [category, setCategory] = useState(product ? product.type : {});
  const [molds, setMolds] = useState(product?.molds?.length > 0 ? product.molds : []);
  const [values, setValues] = useState(product ? product : emptyProduct);

  // useEffect(() => {
  //   console.log(values);
  // }, [values]);

  useEffect (() => {
    setValues(old => {return ({...old, type: category})})
  }, [category])

  const { mutate: mutateAddProduct } = useAddProduct();
  const { mutate: mutateUpdateProduct } = useUpdateProduct();

  let user = useContext(UserContext);

  const handleTextChange = useCallback((e) => {
    let newValues = {};
    newValues[e.target.name] = e.target.value;
    setValues((old) => {
      return { ...old, ...newValues };
    });
  },[setValues])

  const handleCheckboxChange = useCallback((e) => {
    let newValues = {};
    newValues[e.target.name] = !values[e.target.name];
    setValues((old) => {
      return { ...old, ...newValues };
    });
  },[values, setValues])

  function submit(e) {
    e.preventDefault();
    if (product){
      mutateUpdateProduct({ user, product: values });
    } else {
      mutateAddProduct({ user, product: values });
    }
    setModalOpen(false)
  }

  return (
    <form
      onSubmit={submit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        flex: 1,
        // border: "2px solid",
      }}
    >
      <div
        style={{
          display: "flex",
          // border: "2px solid",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            alignItems: "flex-start",
            flexGrow: 1,
            margin: 50,
            // border: "2px solid",
          }}
        >
          <ProductCategorySelect
            category={category}
            setCategory={setCategory}
          />
          <TextField
            // aria-label={qtyEntry.product.name + " field"}
            key={"name"}
            name={"name"}
            size="small"
            color="primary"
            label="Name"
            // placeholder={qtyEntry.product.name}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            margin="dense"
            onChange={handleTextChange}
            value={values.name}
            sx={{
              minWidth: "15ch",
            }}
          />
          <TextField
            // aria-label={qtyEntry.product.name + " field"}
            key={"code"}
            name={"code"}
            size="small"
            color="primary"
            label={"Code Name"}
            // placeholder={qtyEntry.product.name}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            margin="dense"
            onChange={handleTextChange}
            value={values.code}
            sx={{
              minWidth: "15ch",
            }}
          />
          <TextField
            // aria-label={qtyEntry.product.name + " field"}
            key={"mpn"}
            name={"mpn"}
            size="small"
            color="primary"
            label={"MPN"}
            // placeholder={qtyEntry.product.name}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            margin="dense"
            onChange={handleTextChange}
            value={values.mpn}
            sx={{
              minWidth: "15ch",
            }}
          />
          <TextField
            // aria-label={qtyEntry.product.name + " field"}
            key={"unitPrice"}
            name={"unitPrice"}
            size="small"
            color="primary"
            label={"Unit Price"}
            // placeholder={qtyEntry.product.name}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            margin="dense"
            onChange={handleTextChange}
            value={values.unitPrice}
            sx={{
              minWidth: "15ch",
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isPrototype}
                  name="isPrototype"
                  onChange={handleCheckboxChange}
                />
              }
              labelPlacement="end"
              label="Prototype"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="inProduction"
                  checked={values.inProduction}
                  onChange={handleCheckboxChange}
                />
              }
              labelPlacement="end"
              label="In Production"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="forSale"
                  checked={values.forSale}
                  onChange={handleCheckboxChange}
                />
              }
              labelPlacement="end"
              label="For Sale"
            />
          </FormGroup>
          <TextField
            // aria-label={qtyEntry.product.name + " field"}
            key={"notes"}
            name={"notes"}
            size="small"
            color="primary"
            label={"Notes"}
            multiline
            rows={3}
            // placeholder={qtyEntry.product.name}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            margin="dense"
            onChange={handleTextChange}
            value={values.notes}
            sx={{
              minWidth: "15ch",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            alignItems: "flex-start",
            flexGrow: 1,
            margin: 50,
            // border: "2px solid",
          }}
        >
          {category.isSharedMolds ? (
            <MoldsSelect selectedMolds={molds} setSelectedMolds={setMolds}/>
          ) : (
            <TextField
              // aria-label={qtyEntry.product.name + " field"}
              key={"numberOfMolds"}
              name={"numberOfMolds"}
              size="small"
              color="primary"
              label={"Number of Molds"}
              margin="dense"
              onChange={handleTextChange}
              value={values.numberOfMolds}
              sx={{
                minWidth: "15ch",
              }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          // border: "2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="submit"
          color="primary"
          variant="contained"
          margin="dense"
          sx={{
            m: 10,
          }}
        >
          {product ? "Save" : "Add"}
        </Button>
      </div>
    </form>
  );
}
