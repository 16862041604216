import styles from "./ModalContainer.module.css";

export default function ModalContainer({children}) {
  return (
    <div
      className={styles["modal-container"]}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80vw",
        height: "80vh",
        backgroundColor: "#1f1f1f",
        border: "2px solid #ccc",
        padding: 4,
      }}
    >
      {children}
    </div>
  );
}
