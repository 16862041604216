import { useState, useEffect, useContext } from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { UserContext } from '../../contexts'

import { useAddOrder } from "../../hooks/mutations"
import ProductQtyEntry2 from './ProductQtyEntry2'

export default function AddOrderForm() {

    const initialValues = () => {
        let values = {};
        textEntries.forEach(entry => {
            values[entry.name] = ""
        })
        return values
    }

    let textEntries = [
        {
            name: "name",
            label: "Order name",
            placeholder: "Enter order name",
        },
        // {
        //     name: "customerName",
        //     label: "Customer name",
        //     placeholder: "Enter Customer name",
        // },
        // {
        //     name: "customerAddressName",
        //     label: "Customer Address",
        //     placeholder: "Enter Customer Address",
        //     multiline: true,
        // },
        {
            name: "notes",
            label: "Notes",
            placeholder: "Enter notes",
            multiline: true,
        }
    ]

    textEntries.forEach(entry => {
        entry["error"] = false
    })

    const [productQtys, setProductQtys] = useState([]);
    const [values, setValues] = useState(initialValues());
    const [orderType, setOrderType] = useState("production")

    const { mutate: mutateAddOrder } = useAddOrder();

    let user = useContext(UserContext)

    function handleEntryChange(e) {
        let newValues = {};
        newValues[e.target.name] = e.target.value;
        setValues(old => { return { ...old, ...newValues } })
    }

    // useEffect(() => {
    //     console.log(values)
    // },[values])

    function handleTypeChange(e) {
        setOrderType(e.target.value)
    }

    function submit(e) {
        e.preventDefault();
        //TODO: required fields        
        // if (productQtys.length < 1){
        //     //toast product required 

        // }
        let newOrder = {
            type: orderType,
            notes: values.notes == "" || values.notes == undefined ? [] : [values.notes],
            name: values.name,
            products: []
        };
        productQtys.forEach(productQty => {
            let input = productQty.quantity;
            if (input !== "" && input !== "0") {
                let productItem = {}
                productItem.product = productQty.product._id
                productItem.quantity = parseInt(input);
                newOrder.products.push(productItem);
            }
        })
        console.log("new Order", newOrder)
        mutateAddOrder({user: user, order: newOrder})
        setValues(initialValues())
        setProductQtys([])
    }

    return (
        <form
            onSubmit={submit}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                flex: 1,
            }}
        >
            <div 
                style={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "stretch",
                    flexGrow: 1,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "space-around",
                        width: "50%",
                        m: "20px",
                    }}
                >
                    <FormControl
                        margin="dense"
                    >
                        <RadioGroup
                            row
                            aria-labelledby="order type radio group"
                            name="radio-buttons-group"
                            value={orderType}
                            onChange={handleTypeChange}
                        >
                            <FormControlLabel
                                value="shipping"
                                control={<Radio />}
                                label="Shipping"
                                disabled
                            />
                            <FormControlLabel
                                value="production"
                                control={<Radio />}
                                label="Production"
                            />
                        </RadioGroup>
                    </FormControl>
                    {textEntries.map(textEntry => {
                        return (
                            <TextField
                                key={textEntry.name}
                                aria-label={textEntry.name}
                                name={textEntry.name}
                                color="primary"
                                label={textEntry.label}
                                // placeholder={textEntry.placeholder}
                                multiline={textEntry.multiline}
                                // rows={textEntry.rows}
                                margin="normal"
                                error={textEntry.error}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                onChange={handleEntryChange}
                                value={values[textEntry.name]}
                            />
                        )
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flex:1,
                        m: 5,
                        // border: "2px solid"
                    }}
                >
                    <ProductQtyEntry2
                        qtyEntries={productQtys}
                        setQtyEntries={setProductQtys}
                    />
                </div>
            </div>
            <div
                style={{
                    // border: "2px solid",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    margin="dense"
                    sx={{
                        m:10,
                    }}
                >
                    Submit
                </Button>
            </div>
        </form>
    )
}