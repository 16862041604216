import { useContext } from 'react'
import Modal from '@mui/material/Modal'
import { ModalDataContext } from '../contexts'

export default function GlobalModal() {

    let {modalOpen, modalContent, setModalOpen} = useContext(ModalDataContext);

    return (
        <Modal
            // keepMounted
            open={modalOpen}
            onClose = { () => setModalOpen(false) }
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "80vw",
                    height: "80vh",
                    backgroundColor: '#1f1f1f',
                    border: '2px solid #ccc',
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "stretch",
                    alignItems: "stretch",
                    flexWrap: "wrap",
                }}
            >
                {modalContent}
            </div>
        </Modal>
    )
}