import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import foilsOnBeach from '../../media/bannerImages/foils_on_beach.jpg'
import LoginForm from '../forms/LoginForm'
import {useState} from "react";
import PropTypes from "prop-types";
import loginRequest from "../../functions/requests/loginRequests";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

function Login(user, setUser) {


    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundImage: "url(" + foilsOnBeach + ")",
                backgroundColor: "#ffffff00",
                backgroundSize: "cover",
            }}
        >
            <Card sx={{minWidth: '30%', maxWidth: "35%", margin: '45px auto', padding: '5px 10px' }}>
                {/*<form action="#">*/}
                    <CardContent>
                        <Typography align="center" variant="h3" component="div">
                            Login
                        </Typography>
                        <LoginForm user={user} setUser={setUser} />

                    </CardContent>
                {/*    <CardActions>*/}
                {/*        <Button type='submit' variant="contained" size="medium">Login</Button>*/}
                {/*    </CardActions>*/}
                {/*</form>*/}
            </Card>

        </div>
    )
}

export default Login;