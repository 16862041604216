import Typography from '@mui/material/Typography'
import Modal from "@mui/material/Modal";

export function ReplaceContent({
  content,
  style
}) {
    return (
        <Typography
            sx={{...style,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {content}
        </Typography>
    )
}

export function CoverContent({content}) {
    return (
      <Modal
        disablePortal
        // keepMounted
        open={true}
        // onClose = { setOpen(false) }
      >
        {/* <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80vw",
              height: "80vh",
              backgroundColor: "#1f1f1f",
              border: "2px solid #ccc",
              p: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              alignItems: "stretch",
            }}
          > */}
        <Typography
          sx={{
            // width: "100%",
            // height: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {content}
        </Typography>
      </Modal>
    );
}