import { useState, useCallback, useMemo, useContext } from "react";
import { format, isBefore } from "date-fns";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";

import DataField from "../DataField";
import DataList from "../DataList";
import OrderInfo from "../displays/OrderInfo";
import { UserContext, ModalControlContext } from "../../contexts";
import { useCancelOrder } from "../../hooks/mutations/orderMutations.js";

export default function OrderCard({ order, rankSelect }) {
  let { setModalOpen, setModalContent } = useContext(ModalControlContext);

  const [modalStates, setModalStates] = useState({});

  const { mutate: mutateCancelOrder } = useCancelOrder();

  const user = useContext(UserContext);

  let fields = ["priorityWeight"];

  let dateOrStatus = useMemo(() => {
    if (order.status === "completed") {
      return "All products completed";
    } else if (order.status === "pre-completed") {
      return "Finishing products";
    } else if (isBefore(new Date(), new Date(order.estimatedShipDate))) {
      return format(new Date(order.estimatedShipDate), "P");
    } else {
      return "Add more dates to calendar";
    }
  }, [order]);

  const showOrderInfo = useCallback((order) => {
    console.log("info button clicked");
  }, []);

  const cancelOrder = useCallback(
    (order) => {
      console.log("cancel function called");
      mutateCancelOrder({ user: user, orderToCancel: order });
    },
    [order]
  );

  return (
    <Card
      // draggable
      variant="outlined"
      sx={{
        // height: 100,
        margin: "5px",
        display: "flex",
        background: "#333333",
      }}
    >
      <CardContent
        sx={{
          // flex: 1
          width: "100px",
        }}
      >
        {rankSelect}
      </CardContent>
      <CardContent
        sx={{
          // flex: 1
          width: "300px",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            // width: "150px",
            display: "flex",
            // border: "2px solid white",
            // justifyContent: "center"
          }}
        >
          {order.name ? order.name : "[no name]"}
        </Typography>
        <Typography sx={{}}>{order.type + " Order"}</Typography>

        {/* <div
                    style={{
                        margin: "10px"
                    }}
                >
                    Front Wings
                    {getList(order.products, "Front Wing")}
                </div>
                <div>
                    Tail Wings
                    {getList(order.products, "Tail Wing")}
                </div>
                <div>
                    Masts
                    {getList(order.products, "Mast")}
                </div> */}
      </CardContent>
      <CardContent
        sx={{
          flex: 1,
          alignSelf: "center",
        }}
      >
        <Typography variant="body2">{"Est. Complete"}</Typography>
        <Typography variant="body1">{dateOrStatus}</Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // border: "2px solid white",
        }}
      >
        <Button
          size="large"
          onClick={() => {
            setModalContent(
              <OrderInfo
                data={order}
                leftData={fields.map((field) => {
                  return (
                    <DataField
                      key={field}
                      field={field}
                      data={JSON.stringify(order[field])}
                    />
                  );
                })}
                rightData={
                  <DataGrid
                    rows={order.products}
                    getRowId={(row) => row.product._id}
                    columns={[
                      {
                        field: "name",
                        headerName: "Name",
                        valueGetter: (params) => params.row.product.name,
                      },
                      {
                        field: "quantity",
                        headerName: "Quantity",
                      },
                      {
                        field: "inProgress",
                        headerName: "In Progress",
                      },
                      {
                        field: "completed",
                        headerName: "Completed",
                      },
                      {
                        field: "remaining",
                        headerName: "Remaining",
                        valueGetter: (params) => {
                          return (
                            params.row.quantity -
                            (params.row.completed + params.row.inProgress)
                          );
                        },
                      },
                    ]}
                  />
                }
              />
            );
            setModalOpen(true);
          }}
        >
          INFO
        </Button>
      </CardActions>
      {/* <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // border: "2px solid white",
        }}
      >
        <Button
          size="large"
          onClick={() => {showOrderInfo(order)}}
        >
          INFO
        </Button>
      </CardActions> */}
      <CardActions>
        <Button
          size="large"
          onClick={() => {
            cancelOrder(order);
          }}
        >
          CANCEL
        </Button>
      </CardActions>
    </Card>
  );
}

function modalInfo(row) {
  return Object.entries(row).map(([key, value]) => {
    return (
      <div key={key}>{JSON.stringify(key) + " :" + JSON.stringify(value)}</div>
    );
  });
}
