import foilsOnBeach from '../../media/bannerImages/foils_on_beach.jpg'

function Home() {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundImage: "url(" + foilsOnBeach + ")",
                backgroundColor: "#ffffff00",
                backgroundSize: "cover",
            }}
        />
    )
}

export default Home;
