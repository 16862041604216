import { useQuery } from 'react-query';

import compareAsc from 'date-fns/compareAsc'

import { cacheKeys } from "../enums/cacheKeys";

var equal = require('fast-deep-equal');

const config = {
    isDataEqual: (oldData, newData) => {
        return equal(oldData, newData)
    },
    refetchInterval: 60000
}

export function useGetProductCategories(user) {
  return useQuery(
    cacheKeys.ALLPRODUCTCATEGORIES,
    async () => {
      if (user) {
        let response = await fetch("/api/productCategories", {
          method: "GET",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
        });
        return response.json();
      }
    },
    config
  );
}

export function useGetProducts(user) {
    return useQuery(cacheKeys.ALLPRODUCTS, 
        async () => {
            if (user) {
                let response = await fetch('/api/products', {
                    method: "GET",
                    headers: {
                        authorization: "Bearer " + user?.jwt,
                        'Content-Type': "application/json"
                    },
                })
                return response.json()
            }
        },
        config
    )
}

export function useGetMolds(user) {
  return useQuery(
    cacheKeys.ALLMOLDS,
    async () => {
      if (user) {
        let response = await fetch("/api/molds", {
          method: "GET",
          headers: {
            authorization: "Bearer " + user?.jwt,
            "Content-Type": "application/json",
          },
        });
        let molds = await response.json();
        return molds.sort((a,b) => {return(a.name < b.name ? -1: 1)})
      }
    },
    config
  );
}

export function useGetOrders(user) {
    return useQuery(cacheKeys.ALLORDERS, 
        async () => {
            if (user) {
                let response = await fetch('/api/orders', {
                    method: "GET",
                    headers: {
                        authorization: "Bearer " + user.jwt,
                        'Content-Type': "application/json"
                    },
                })
                return response.json()
            }
        },
        config
    )
}

export function useGetDateSchedule(user) {
    return useQuery('dateSchedule', 
        async () => {
            if (user) {
                let response = await fetch('/api/dateSchedule', {
                    method: "GET",
                    headers: {
                        authorization: "Bearer " + user?.jwt,
                        'Content-Type': "application/json"
                    },
                })
                let result = await response.json()
                result.dates = result.dates.map(d => new Date(d))
                result.dates.sort((a,b) => compareAsc(a,b));
                return result
            }
        },
        config
    )
}

export function useGetSchedule(user) {
    return useQuery(cacheKeys.FOILSCHEDULE,
        async () => {
            if (user) {
                console.log("sending foilSchedule request")
                let response = await fetch('/api/schedules/624e9e01b7002b3a9dd837b6', {
                    method: "GET",
                    headers: {
                        authorization: "Bearer " + user?.jwt,
                        'Content-Type': "application/json"
                    },
                })
                let schedule = await response.json()
                return schedule
            }
        },
        config
    )
}

export function useGetFutureDates(user) {
    return useQuery(
      cacheKeys.ALLFUTUREDATES,
      async () => {
        console.log("sending futureDates request");
        if (user) {
          let response = await fetch("/api/futureDates", {
            method: "GET",
            headers: {
              authorization: "Bearer " + user?.jwt,
              "Content-Type": "application/json",
            },
          });
          let results = await response.json();
          results.forEach((d) => (d.date = new Date(d.date)));
          results.sort((a, b) => compareAsc(a.date, b.date));
          return results;
        }
      },
      config
    );
}

export function useGetPastDates(user) {
    return useQuery(
      cacheKeys.ALLPASTDATES,
      async () => {
        if (user) {
          console.log("sending pastDates request");
          let response = await fetch("/api/pastDates", {
            method: "GET",
            headers: {
              authorization: "Bearer " + user?.jwt,
              "Content-Type": "application/json",
            },
          });
          let result = await response.json();
          result.map((d) => {
            d.date = new Date(d.date);
            return d;
          });
          return result;
        }
      },
      config
    );
}