import DataPage from '../wrappers/DataPage'
import AddOrderForm from '../forms/AddOrderForm'


export default function AddOrderPage() {

    return (
        <DataPage
            Content={<AddOrderForm />}
            title={"Add Order"}
        />
    )
}