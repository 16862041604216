export const cacheKeys = {
  ALLORDERS: "orders",
  ALLCANCELLEDORDERS: "cancelledOrders",
  FOILSCHEDULE: "foilschedule",
  ALLPRODUCTS: "products",
  ALLFUTUREDATES: "futureDates",
  ALLPASTDATES: "allPastDates",
  ALLPRODUCTCATEGORIES: "allProductCategories",
  ALLMOLDS: "allMolds",
};
