import { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import { blueDarkTheme } from "./styling/themes";
import jwt from "jwt-decode";
import AppRoutes from "./components/AppRoutes";
import { UserContext, ModalControlContext, ModalDataContext } from "./contexts";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// const ProductsTable = lazy(() => import('./components/ProductsTable'));

// import userState from './UserState';

const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("token")));
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [decodedUser, setDecodedUser] = useState({});

  useEffect(() => {
    if (user) {
      const jwtDecoded = jwt(user.jwt);
      setDecodedUser(jwtDecoded);
    } else {
      setDecodedUser({});
    }
  }, [user]);

  useEffect(() => {
    const storedUser = localStorage.getItem("token");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(false);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={blueDarkTheme}>
        <CssBaseline />
        <UserContext.Provider value={user}>
          <ModalControlContext.Provider
            value={{ setModalContent, setModalOpen, modalOpen }}
          >
            <ModalDataContext.Provider
              value={{ modalContent, setModalContent, modalOpen, setModalOpen }}
            >
              <AppRoutes
                user={user}
                setUser={setUser}
                decodedUser={decodedUser}
              />
            </ModalDataContext.Provider>
          </ModalControlContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
