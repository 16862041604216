import { useContext } from "react";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from '@mui/material/Paper'

import { UserContext } from "../../contexts";
import { useGetProducts } from "../../hooks/queries";
import { ReplaceContent } from "../Transitions";

export default function ProductQtyEntry2({ qtyEntries, setQtyEntries }) {
  const user = useContext(UserContext);

  const {
    isLoading: productsLoading,
    isError: productsError,
    data: products,
  } = useGetProducts(user);

  function handleProductQtyChange(e) {
    if (e.target.value >= 0 || e.target.value === "") {
      setQtyEntries((oldEntries) => {
        let newEntries = [...oldEntries];
        let entryIndexToUpdate = oldEntries.findIndex((oldEntry) => {
          return oldEntry.product._id === e.target.name;
        });
        let newEntry = newEntries[entryIndexToUpdate];
        newEntry.quantity = e.target.value;
        newEntries.splice(entryIndexToUpdate, 1, newEntry);
        return newEntries;
      });
    } else {
      //TODO: trigger error message on TextInput
    }
  }

  function handleSelectChange(e) {
    setQtyEntries((old) => {
      return [...old, { product: e.target.value, quantity: "" }];
    });
  }

  if (productsLoading) {
    return <ReplaceContent content="Loading..." />;
  }
  if (productsError) {
    return <ReplaceContent content="Error loading. Please refresh page." />;
  }

  return (
    <Paper
      sx={{
        // border: "2px solid",
        minWidth: 200,
        maxHeight: '60vh',
        overflow: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
      >
        Recount
      </Typography>
      <FormControl
        fullWidth
        size="small"
        sx={{
          mt: 1,
          display: "flex",
          alignItems: "stretch",
        }}
      >
        {" "}
        {/*TODO: implement product type selector, which activated product selector which only shows products of the selected type*/}
        <InputLabel id="label">Select Product</InputLabel>
        <Select
          autoWidth
          labelId="label"
          id="select"
          value=""
          label="Add Product"
          onChange={handleSelectChange}
        >
          {products.reduce((result, product) => {
            if (
              !qtyEntries.find(
                (qtyEntry) => qtyEntry.product._id === product._id
              )
            ) {
              result.push(
                <MenuItem key={product._id} value={product}>
                  {product.name}
                </MenuItem>
              );
            }
            return result;
          }, [])}
        </Select>
      </FormControl>
      {qtyEntries.map((qtyEntry) => {
        return (
          <div
            key={qtyEntry.product._id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TextField
              aria-label={qtyEntry.product.name + " field"}
              key={qtyEntry.product._id}
              name={qtyEntry.product._id}
              // size="small"
              color="primary"
              label={qtyEntry.product.name}
              // placeholder={qtyEntry.product.name}
              // InputLabelProps={{
              //     shrink: true,
              // }}
              margin="dense"
              onChange={handleProductQtyChange}
              value={qtyEntry.quantity}
              sx={{
                width: "15ch",
              }}
            />
            {/* <Typography
              sx={{
                marginLeft: 2,
                width: 15,
              }}
            >
              {qtyEntry.quantity ? `(${qtyEntry.quantity})` : null}
            </Typography> */}
          </div>
        );
      })}
    </Paper>
  );
}
