import { useEffect, useContext, useCallback} from "react";

import Typography from '@mui/material/Typography'
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { UserContext } from "../../contexts";
import { ReplaceContent } from "../Transitions";
import {useGetMolds} from '../../hooks/queries'

export default function MoldsSelect({
  selectedMolds,
  setSelectedMolds,
}) {

  console.log("Rendering MoldsSelect");

  let user = useContext(UserContext);

  const { isLoading, isError, data: allMolds } = useGetMolds(user);

  let handleCheckboxChange = useCallback((e) => {
    console.log("starting Molds", selectedMolds)
    let clickedMold = e.target.parentElement.dataset.mold;
    console.log("dataset.mold", clickedMold);
    let clickedIndex = selectedMolds.findIndex(selectedMold => selectedMold === clickedMold)
    console.log("index", clickedIndex)
    if (clickedIndex > -1) {
      setSelectedMolds(old => {
        let oldClone = [...old]
        oldClone.splice(clickedIndex)
        return oldClone
      })
    } else {
      setSelectedMolds(old => [...old, clickedMold])
    }
  },[selectedMolds, setSelectedMolds])

  if (isLoading) {
    return <ReplaceContent content="Loading..." />;
  }
  
  if (isError) {
    return <ReplaceContent content="Error loading. Please refresh page." />;
  }

  return (
    <FormGroup>
      <Typography variant="h6">
        Molds
      </Typography>    
      {allMolds.map(mold => 
        {return (
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedMolds.some(
                  (selectedMold) => selectedMold === mold._id
                )}
                name={mold.name}
                data-mold={mold._id}
              />
            }
            onChange={handleCheckboxChange}
            labelPlacement="end"
            label={mold.name}
          />
        );}
      )}
    </FormGroup>
  );
}
