import { useState, useEffect, useCallback, useContext } from "react";

import Button from '@mui/material/Button'

import { UserContext } from "../../contexts";
import { useGetProducts } from "../../hooks/queries";
import ProductQtyEntry2 from "./ProductQtyEntry2";
import { ReplaceContent } from "../Transitions";
import {useRecountInventory} from '../../hooks/mutations/pastDateMutations'

export default function InventoryRecount({
  pastDate
}) {
  let user = useContext(UserContext);

  const { mutate: mutateRecountInventory } = useRecountInventory();

  const { isLoading, isError, data: products } = useGetProducts(user);

  const [productCounts, setProductCounts] = useState([]);

  // useEffect(() => {
  //   let newProductCounts = [...productCounts];
  //   if (isLoading || isError) {return}
  //   products.forEach((product) => {
  //     let foundProductCount = newProductCounts.find((newProductCount) => {
  //       return product._id === newProductCount.product._id;
  //     });
  //     if (foundProductCount === undefined) {
  //       newProductCounts.push({ product: product, quantity: "" });
  //     }
  //   });
  //   setProductCounts(newProductCounts);
  // }, [products]);

  function submit() {
    let foilFinishDept = pastDate.departments.find(
      (department) => department.department.name === "foilFinish"
    );
    let newFoilFinishDept = {
      ...foilFinishDept,
      recount: { products: productCounts },
    };
    mutateRecountInventory({user, pastDate, departments: [newFoilFinishDept]})
  };

  if (isLoading) {
    return <ReplaceContent content="Loading..." />;
  }
  if (isError) {
    return <ReplaceContent content="Error loading. Please refresh page." />;
  }

  return (
    <div>
      <ProductQtyEntry2
        qtyEntries={productCounts}
        setQtyEntries={setProductCounts}
      />
      <Button
        variant="contained"
        onClick={submit}
        color="primary"
        sx={{
          m: 2,
        }}
      >
        Submit
      </Button>
    </div>
  );
}
